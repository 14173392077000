import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import cookie from "react-cookies";
import { apiUrl, unquieID, headerconfig } from "../Settings/Config";

import Header from "../Layout/Header";
var qs = require("qs");
class Checkout extends Component {
  constructor(props) {
    super(props);
    var UserId = cookie.load("UserId") || "";
    this.state = {
      current_page: "Direct Pay",
      checkoutType: "BillPay",
      clientLogo: "",
      backLink: "",
      UserId:UserId
    };

    if (UserId === undefined) {
      props.history.push("/");
    }
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.checkPaymentStatus();
  }

  checkPaymentStatus() {
    var urlParams = new URLSearchParams(this.props.location.search);
    var transactionID = urlParams.get("transaction_id") || "";
    if(transactionID!=="") {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      payment_refID: transactionID,
      customer_id: this.state.UserId,
    };
    axios
      .post(
        apiUrl + "paymentV2/checkStatusReddot",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {        
           Swal.fire({
            title: "Error",
            html: 'Cancelled By Customer',
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push("/directpay/checkout");
        }
      });
    }else {
      Swal.fire({
        title: "Error",
        html: 'Cancelled By Customer',
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary waves-effect waves-light",
        },
        buttonsStyling: false,
      });
      this.props.history.push("/directpay/checkout");
    }
  }


  render() {
    
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div class="lds-hourglass"></div>
          </div>
        </div>
      );
  }
}

export default Checkout;

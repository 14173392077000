import React, { Component } from "react";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import { Rating } from "react-simple-star-rating";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import companyLogo from "../../common/images/companyLogo.png";
import arrow from "../../common/images/success-tick.svg";
import offerimage from "../../common/images/offerimage.png";
import voucher2 from "../../common/images/voucher2.png";
import star from "../../common/images/star.svg";
import staractive from "../../common/images/star-active.svg";

class DirectPay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: "applepay",
      ratingValue: 0,
      name: "",
      email: "",
      contactNumber: "",
      feedback: "",
    };
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
  }
  handleRating(rate) {
    this.setState({ ratingValue: rate });
  }
  submitReview(e) {
    e.preventDefault();
    if (this.state.ratingValue > 3) {
      window.open(
        "https://search.google.com/local/writereview?placeid=ChIJQz-hqkQR2jERBthR5rOvmgg",
        "_blank"
      );
    } else {
      this.setState({ googleRating: true });
    }
  }

  handleFldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }

  submitRating() {
    
  }


  render() {
    return (
      <div className="directpay-new home-page">
        <header className="header">
          <div className="header-action header-center-txt">
            <div className="container">
              <div className="ha-lhs"></div>
              <div className="ha-rhs">
                <ul>
                  <li className="profile-user">
                    <a href="/">
                      <img
                        src="/static/media/user.529b688b202e33d5cb9ed9fdbd5e666d.svg"
                        alt="user"
                      />
                    </a>
                  </li>
                  <li className="navsbar">
                    <a href="/">
                      <img
                        src="/static/media/navigation.55fc7d1db67e06740b886e7c6bb60183.svg"
                        alt="nav"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>

        <div className="container">
          <div className="pament-success">
            <div className="payment-confirmation">
              <div className="success-heading">
                <div className="icon">
                  <img src={arrow} alt="" />
                </div>

                <h2>
                  Thank You For Your <br /> Payment
                </h2>
              </div>
              <div className="details">
                <div className="table-sec">
                  <div className="table-no">Table 5</div>
                  <p>Aldenaire Kitchen - Bugis Street</p>
                </div>
                <div className="payment-info">
                  <div className="paid-info">
                    <div>
                      <strong>You Paid</strong>
                    </div>
                    <div>
                      {" "}
                      <span>$7.50</span>
                    </div>
                  </div>
                  <p>You payed for your share</p>
                </div>
                <div className="payment-info">
                  <div className="paid-info">
                    <div>
                      <strong>Points You Earned</strong>
                    </div>
                    <div>
                      {" "}
                      <span>$7.50</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="review-section">
              <p>How's your overall experience?</p>
              <div className="stars">
                <Rating
                  initialValue={this.state.ratingValue}
                  onClick={this.handleRating.bind(this)}
                />
              </div>
              <a
                href={"/"}
                onClick={this.submitReview.bind(this)}
                className="review-btn"
              >
                Review This Place
              </a>
            </div>

            <div className="voucher-section">
              <div className="voucher-card">
                <img
                  src={offerimage}
                  alt="Voucher"
                  className="thanks-voucher-image"
                />
                <a href="#" className="voucher-btn">
                  Discover Our Vouchers
                </a>
              </div>
              <div className="voucher-card">
                <img
                  src={voucher2}
                  alt="Birthday Voucher"
                  className="thanks-voucher-image"
                />
                <a href="#" className="voucher-btn">
                  Claim Your Birthday Voucher
                </a>
              </div>
            </div>
          </div>
        </div>
        <BottomSheet
          open={this.state.googleRating}
          onDismiss={() => {
            this.setState({ googleRating: false });
          }}
        >
          <div className="container">
            <div className="update-personal internal-rating">
              <div className="up-header textcenter">
                <p>How’s your overall experience?</p>
                <Rating
                  initialValue={this.state.ratingValue}
                  onClick={this.handleRating.bind(this)}
                />
              </div>
              <div className="up-form">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleFldChange.bind(this)}
                />
                <input
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleFldChange.bind(this)}
                />

                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contactNumber"
                  value={this.state.contactNumber}
                  onChange={this.handleFldChange.bind(this)}
                />
                <textarea
                  placeholder="Let us know your feedback"
                  name="feedback"
                >
                  {this.state.feedback}
                </textarea>
                {this.state.regcust_error != "" && (
                  <p className="error_info">{this.state.regcust_error}</p>
                )}
                <div className="up-frm-btn">
                  <a
                    href={void 0}
                    className="button cust-reg-btn"
                    onClick={this.submitRating.bind(this)}
                  >
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  }
}

export default DirectPay;

import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import axios from "axios";
import cookie from "react-cookies";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import {
  GET_CUSTOMER_DETAILS,
  GET_MISSIONS_LIST,
  GET_REWARDSETTING_LIST,
} from "../../actions";
import { apiUrl, unquieID, companyLogo } from "../Settings/Config";
import {
  ordinalSuffixOf,
  showPriceSeparate,
  showPriceValue,
} from "../Helpers/SettingHelper";
import "../../common/css/owl.carousel.css";
import { Instagram } from "react-content-loader";

import coin from "../../common/images/coin.svg";
import tick from "../../common/images/tick-red.svg";
import logo from "../../common/images/epicpay.png";
import walletlight from "../../common/images/wallet.svg";
import menuicon from "../../common/images/menu-icon.svg";
import paybillicon from "../../common/images/paybill-icon.svg";
import offerimage from "../../common/images/offerimage.png";
import voucherticket from "../../common/images/voucher-ticket.svg";
import selectedVoucher from "../../common/images/selectedVoucher.png";
import blueround from "../../common/images/blueround.png";
import startop from "../../common/images/star-top.png";
import starbottom from "../../common/images/star-bottom.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
var base64 = require("base-64");
var Parser = require("html-react-parser");
class Myaccount extends Component {
  constructor(props) {
    super(props);
    let missiontype = "Monthly";
    this.state = {
      current_page: "guesthome",
      hideBack: "yes",
      designType: "new",
      withheaderbg: "yes",
      allowToShowCompany: "yes",
      customerData: [],
      missionList: [],
      mission_type: missiontype,
      rewardsettingsdata: [],
      subscriptionLoad: true,
      subscriptionList: [],
      dispalySubscription: "",
      redeemAmount: "",
      showRedeemAmount: false,
      voucherList: [],

      gSettings: "",
      clientLogo: "",
      companyName: "",
      typeofCreditRedeem: 1,
      welcomeVoucher: [],
      selectedVoucher: "",
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }

    if (unquieID === "") {
      props.history.push("/home");
    }

    var customerId = cookie.load("UserId");
    this.props.getCustomerDetails(
      "&customer_id=" + customerId + "&common_setting=yes"
    );
    this.props.getMissionsList(
      "mission_type=" + missiontype + "&customer_id=" + customerId
    );
    this.props.getRewardSettingList("&customer_id=" + customerId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSettings();
    this.getProductData();
    this.loadSubscription();
    this.loadWelcomeVoucher();
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails });
    }
    if (this.state.missionList !== PropsDt.missionslis) {
      this.setState({ missionList: PropsDt.missionslis });
    }
    if (this.state.rewardsettingsdata !== PropsDt.rewardsettingslist) {
      this.setState({ rewardsettingsdata: PropsDt.rewardsettingslist });
    }
  }
  loadWelcomeVoucher() {
    var customerID = cookie.load("UserId") || "";
    var urlShringTxt =
      apiUrl +
      "products/products_list/?app_id=" +
      unquieID +
      "&welcomevoucher=1&customerId=" +
      customerID;
    axios
      .get(urlShringTxt, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            welcomeVoucher: res.data.result_set,
          });
        }
      });
  }
  gotoWelcomeVoucher(selectedVoucher) {
    if (selectedVoucher.alreadyredeem === "no") {
      if (this.state.loginType === "Guest") {
        this.setState({ selectedVoucher: selectedVoucher, openLogin: true });
      } else {
        this.props.history.push(
          "/buy-vouchers/" + selectedVoucher.product_slug
        );
      }
    }
  }
  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          this.setState({
            gSettings: result,
            clientLogo: result?.company_logo,
            companyName: result?.company_name,
            typeofCreditRedeem: result?.typeofCreditRedeem || 1,
          });
        }
      });
  }
  getProductData() {
    axios
      .get(
        `${apiUrl}products/products_list?app_id=${unquieID}&product_type=7&recomentedVoucher=Yes`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ voucherList: res.data.result_set });
        }
      });
  }

  loadSubscription() {
    var userID = cookie.load("UserId") || "";
    if (unquieID !== "" && userID !== "") {
      axios
        .get(
          apiUrl +
            "subscription/listsubscription?customer_id=" +
            cookie.load("UserId") +
            "&UniqueID=" +
            unquieID +
            "&status=A",
          {
            headers: { Authorization: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState(
              {
                subscriptionList: res.data.result,
              },
              function () {
                this.dispalySubscription();
              }
            );
          } else {
            this.setState({ subscriptionLoad: false });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  dispalySubscription() {
    var subscriptionList = "";
    if (this.state.subscriptionList.length > 0) {
      subscriptionList = this.state.subscriptionList.map((item, index) => {
        var subscribePeriod = "";
        if (item.subscribe_period === "W") {
          subscribePeriod = "Weekly";
        } else if (item.subscribe_period === "M") {
          subscribePeriod = "Monthly";
        } else if (item.subscribe_period === "Q") {
          subscribePeriod = "Quarterly";
        } else if (item.subscribe_period === "B") {
          subscribePeriod = "Biannually";
        } else if (item.subscribe_period === "A") {
          subscribePeriod = "Annually";
        }
        /* var subscribstatus = "";
        if (item.subscribe_status === "A") {
          subscribstatus = "Active";
        } else if (item.subscribe_status === "I") {
          subscribstatus = "Inactive";
        } else if (item.subscribe_status === "C") {
          subscribstatus = "Cancelled";
        } else if (item.subscribe_status === "P") {
          subscribstatus = "Pending";
        } */
        var subscribe_vouchers =
          item.subscribe_vouchers !== "" && item.subscribe_vouchers !== null
            ? JSON.parse(item.subscribe_vouchers)
            : [];
        return (
          <li key={index}>
            <span>
              <b>
                {item.subscribe_item_qty} X {subscribePeriod}{" "}
                {item.subscribe_item_name}{" "}
              </b>
              {/* <b
                className={
                  item.subscribe_status === "C" ? "subscribe-cancel" : ""
                }
              >
                {subscribstatus}
              </b> */}
              <br /> Started: {item.subscribe_start_date} | Next:{" "}
              {item.subscribe_next_cycle}
            </span>
            <Link
              to={"/subscription-history/" + base64.encode(item.subscribe_id)}
              className="subscribe-view"
            >
              View
            </Link>{" "}
            <figure>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip
                id={"happyFace" + index}
              ></i>
              <ReactTooltip
                anchorId={"happyFace" + index}
                type="success"
                place="left"
              >
                {item.subscribe_item_type === "product" ? (
                  <b>Voucher Subscription</b>
                ) : (
                  <b>Store Subscription</b>
                )}
                {subscribe_vouchers.length > 0 &&
                  subscribe_vouchers.map((sItem, sIndex) => {
                    return (
                      <div key={sIndex}>
                        {sItem.quantity} X {sItem.product_name}
                      </div>
                    );
                  })}
              </ReactTooltip>
            </figure>
          </li>
        );
      });
      this.setState({
        subscriptionList: subscriptionList,
        subscriptionLoad: false,
      });
    }
  }

  goToNavPage(pageTxt, event) {
    event.preventDefault();
    this.props.history.push("/" + pageTxt);
  }
  gotoRedeemFun(event) {    
    event.preventDefault();
    this.props.history.push("/directpay/checkout");
   /*  if (this.state.customerData?.common_setting?.typeofCredit === "2") {
      this.setState({ showRedeemAmount: true });
    } else {
      this.props.history.push("/scanqrcode");
    } */
  }
  onDismiss() {
    this.setState({ showRedeemAmount: false });
  }
  scanRedeemQR(e) {
    e.preventDefault();
    var details = {
      redeemAmount: this.state.redeemAmount,
    };
    /*  this.props.history.push({
      pathname: "/redeemqr/OUT-94197-4-3",
      state: details,
    });  */
    this.props.history.push({
      pathname: "/scanqrcode",
      state: details,
    });
  }

  getmissionData(missionList) {
    const missionsRowHtml = missionList.map((missionsRow, rwInt) => {
      let msnCls = missionsRow.is_earned === "Yes" ? "" : "active";
      let noofTrans = parseInt(missionsRow.mission_noof_transaction);
      return (
        <li className={msnCls} key={rwInt}>
          <div className="mission-top">
            <figure>
              <img src={missionsRow.mission_info_icon} alt="missonicon" />
              <span>
                {rwInt === 0 && (
                  <img className="active-img" src={tick} alt="trans" />
                )}{" "}
                {ordinalSuffixOf(noofTrans)} Transaction
              </span>
            </figure>
            {/*<strong>Bonus +{missionsRow.mission_bonus_points} </strong>*/}
            <strong> +{missionsRow.mission_bonus_points} points </strong>
          </div>
          {/*<div className="mission-btm">Validity period : 06-11-2023</div>*/}
          {/*<div className="mission-btm">Validity period : {missionsRow.mission_bonuspoints_validity} days</div>*/}
        </li>
      );
    });

    return <ul>{missionsRowHtml}</ul>;
  }

  getmissionDataOld(missionList) {
    const missionsRowHtml = missionList.map((missionsRow, rwInt) => {
      let msnCls = "active";
      let noofTrans = parseInt(missionsRow.mission_noof_transaction);
      return (
        <li className={msnCls}>
          <figure>
            <img src={missionsRow.mission_info_icon} alt="missonicon" />
            <span>
              {rwInt === 0 && (
                <img className="active-img" src={tick} alt="trans" />
              )}{" "}
              {ordinalSuffixOf(noofTrans)} Transaction
            </span>
          </figure>
          <strong>Bonus +{missionsRow.mission_bonus_points} points</strong>
        </li>
      );
    });

    return <ul>{missionsRowHtml}</ul>;
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  rewardProLst(membership_spent_amount) {
    if (this.state.rewardsettingsdata.length > 0) {
      const rewardsettingsHtml = this.state.rewardsettingsdata.map(
        (rewardsettings, rwInt) => {
          let msnCls = rewardsettings.is_earned === "Yes" ? "active" : "";
          let proImg =
            rewardsettings.reward_freeproduct_image !== "" ? (
              <img src={rewardsettings.reward_freeproduct_image} alt="Coin" />
            ) : (
              ""
            );
          return (
            <li key={rwInt} className={msnCls}>
              <figure>{proImg}</figure>
              <div className="reward-reach">
                <em>{rewardsettings.reward_freeproduct_name}</em>
                <span>
                  {showPriceValue(rewardsettings.reward_pointstoreach)}{" "}
                </span>
              </div>
            </li>
          );
        }
      );

      var calcMemberPerc = 0;
      let indxVal = Object.keys(this.state.rewardsettingsdata).length - 1;
      let membership_max_amount = parseInt(
        this.state.rewardsettingsdata[indxVal].reward_pointstoreach
      );

      if (membership_max_amount) {
        if (
          parseFloat(membership_spent_amount) >
          parseFloat(membership_max_amount)
        ) {
          calcMemberPerc = 100;
        } else {
          calcMemberPerc =
            (membership_spent_amount / membership_max_amount) * 100;
        }
      }

      return (
        <>
          <div className="rewards-uget">
            <h2>Rewards You Get</h2>
            <span>Spend Your Credits To Unlock Exciting Vouchers</span>
            <ul>{rewardsettingsHtml}</ul>
          </div>
          <div className="ovr-view-inner">
            <div className="slide-frame">
              <div
                className="inner-frame-bg"
                style={{ width: calcMemberPerc + "%" }}
              ></div>
            </div>
            <div className="slide-frame1 active"></div>
            <div
              className={
                parseFloat(calcMemberPerc) === 100
                  ? "slide-frame2 active"
                  : "slide-frame2"
              }
            ></div>
            {this.rewardActLst(membership_spent_amount)}
          </div>
        </>
      );
    }
  }

  rewardActLst(membership_spent_amount) {
    const rewardsettingsHtml = this.state.rewardsettingsdata.map(
      (rewardsettings, rwInt) => {
        let msnCls =
          parseFloat(membership_spent_amount) >=
          parseFloat(rewardsettings.reward_pointstoreach)
            ? "active"
            : "";
        return (
          <li key={rwInt} className={msnCls}>
            {showPriceValue(rewardsettings.reward_pointstoreach)}
          </li>
        );
      }
    );

    return (
      <ul>
        <li className="active">0</li>
        {rewardsettingsHtml}
      </ul>
    );
  }
  handleFldChange(e) {
    this.setState({ redeemAmount: e.target.value });
  }
  gotoVoucherDetails(voucherLink) {
    this.props.history.push(voucherLink);
  }

  render() {
    let customerData = this.state.customerData;
    let missionList = this.state.missionList;
    if (Object.keys(customerData).length > 0 && this.state.gSettings !== "") {
      let availablePoints = customerData?.custmap_available_points || 0;
      let membership_spent_amount = 0;
      let commonSetting = customerData?.common_setting || [];
      var typeofCreditRedeem = "1";
      if (
        Object.keys(commonSetting).length > 0 &&
        customerData.customer_membership_displayname !== undefined &&
        customerData.customer_membership_displayname !== "" &&
        customerData.customer_membership_displayname !== null
      ) {
        membership_spent_amount = customerData?.custmap_earned_points || 0;
        typeofCreditRedeem = commonSetting?.typeofCreditRedeem || 1;
      }

      return (
        <div className="directpay-new home-page">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div className="container">
            <div className="home-menu">
              {this.state.loginType === "Guest" && (
                <div className="login-section">
                  <a href="/" className="login-link">
                    If you already have an account,{" "}
                    <span>Click here to Login</span>
                  </a>
                </div>
              )}

              <div className="button-container">
                <a href="#" className="btn view-menu">
                  <img src={menuicon} alt="" />
                  <span>View Menu</span>
                </a>
                <Link to={`/directpay/checkout`} className="btn pay-bill">
                  <img src={paybillicon} alt="" />
                  <span>Pay Bill</span>
                </Link>
              </div>
            </div>

            <div className="cash-voucher-section">
              {parseFloat(customerData.custmap_available_credits) > 0 && (
                <div className="voucher-card">
                  <div className="voucher-info">
                    <div className="voucher-title">Your Cash Vouchers</div>
                    <div className="voucher-amount">
                      <img src={voucherticket} alt="" />
                      {showPriceSeparate(
                        customerData.custmap_available_credits,
                        2,
                        "normal"
                      )}
                    </div>
                    <Link to={"/history/cashvoucher"}>View History</Link>
                  </div>
                  <a
                    href="#"
                    className="redeem-btn"
                    onClick={this.gotoRedeemFun.bind(this)}
                  >
                    Redeem
                  </a>
                </div>
              )}
              {parseFloat(availablePoints) > 0 && (
                <div className="points-card">
                  <div className="points-info">
                    <div className="points-title">Earned Points</div>
                    <div className="points-amount">
                      <img src={coin} alt="" /> {availablePoints}
                    </div>
                  </div>
                  <a
                    href="#"
                    className="redeem-btn"
                    onClick={this.goToNavPage.bind(this, "vouchers")}
                  >
                    Redeem
                  </a>
                </div>
              )}
            </div>

            {this.state.welcomeVoucher.length > 0 &&
            <div className="home-menu">
              {this.state.welcomeVoucher.map((item) => {
                return (
                  <div
                    className="promotion-section"
                    onClick={this.gotoWelcomeVoucher.bind(this, item)}
                  >
                    <img
                      src={
                        item.product_thumbnail !== "" &&
                        item.product_thumbnail !== null
                          ? item.product_thumbnail
                          : offerimage
                      }
                      alt="Breakfast offer"
                      className="promo-image"
                    />
                    <div
                      className={`promo-text ${
                        item.alreadyredeem === "yes" ? "used" : ""
                      }`}
                    >
                      <p>
                        {item.alreadyredeem === "yes"
                          ? "Already issued"
                          : "Claim Your Free Welcome Voucher"}
                      </p>
                    </div>
                  </div>
                );
              })}
              </div>}

            {this.state.voucherList.length > 0 && (
              <div className="vouchersList">
                <h1>Buy Vouchers</h1>
                <div className="vouchers ">
                  {this.state.voucherList.map((item, index) => {
                    if(index<=2) {
                    return (
                      <div key={index}>
                      <div
                        className={`voucher${item.giftProduct!==null?' avail-free':''}`}
                        
                        onClick={this.gotoVoucherDetails.bind(
                          this,
                          "/buy-vouchers/" + item.product_slug
                        )}
                      >
                        <div>
                          <img src={blueround} className="tickimg" />
                          <h2>
                            <span>{item.discountPrecentage}%</span>
                            <br />
                            {this.state.typeofCreditRedeem === "2"
                              ? "Discount"
                              : "Bonus"}
                          </h2>
                        </div>
                        <div className="pay-amount-parent">
                          <p>Pay</p>
                          <p className="pay-amount">
                            {showPriceSeparate(item.product_price, 0, "no")}
                          </p>
                        </div>

                        <p className="plus">+</p>

                        <div className="bonus">
                          <p>Bonus</p>
                          <p className="bonus-amount">
                            {this.state.typeofCreditRedeem === "2"
                              ? item.discountPrecentage + "%"
                              : showPriceSeparate(
                                  item.product_voucher_bonus_amount,
                                  0,
                                  "no"
                                )}
                          </p>
                        </div>                        
                      </div>
                      {item.giftProduct!==null &&
                        <div className="free-vouchers">
                          + FREE {item.giftProduct.product_name}
                        </div>
                      }
                      </div>
                    );
                  }
                  })}
                </div>
                <div className="arrow">
                  <Link to={"/buy-vouchers"} className="arrow-link">
                    View All Vouchers
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div className="rel">
            <div className="container">
              {customerData?.common_setting?.enable_subscription === true && (
                <div
                  className={`subs-create ${
                    this.state.subscriptionLoad === false &&
                    this.state.subscriptionList !== "" &&
                    "subs-created"
                  }`}
                >
                  {this.state.subscriptionLoad === false &&
                  this.state.subscriptionList !== "" ? (
                    <>
                      <div className="inner-subs-sub">
                        <div className="subs-lhs">
                          <h3>Subscription</h3>
                        </div>
                        <div className="subs-rhs">
                          <Link to={"/subscription"} className="button">
                            Create
                          </Link>
                          <Link
                            to={"/subscription-history"}
                            className="manage-subs"
                          >
                            Manage
                          </Link>
                        </div>
                      </div>
                      {this.state.subscriptionLoad === false ? (
                        <ul className="my-subscription">
                          {this.state.subscriptionList}
                        </ul>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div className="inner-subs">
                      <h3>Subscription</h3>
                      <p>You don’t have any active subscription</p>
                      <Link to={"/subscription"} className="button">
                        Create
                      </Link>
                    </div>
                  )}
                </div>
              )}

              <div className="upcoming-trans">
                {this.rewardProLst(membership_spent_amount)}
              </div>
              {/* Object.keys(missionList).length > 0 && (
                <div className="monthly-rewards">
                  <h2 className="textcenter">
                    {this.state.mission_type} Rewards
                  </h2>
                  {this.getmissionData(missionList)}
                </div>
              ) */}
            </div>
          </div>

          <Footer {...this.props} />

          <BottomSheet
            open={this.state.showRedeemAmount}
            onDismiss={this.onDismiss.bind(this)}
          >
            <div className="container">
              <div className="welcome-phone">
                <div className="wp-top">
                  <h2>Redeem Your Cash Voucher</h2>
                  <p>Please enter credit amount to redeem here</p>
                </div>
                <div className="input-phone">
                  <div className="prefix-merge">
                    <input
                      type="number"
                      step="0.01"
                      inputmode="decimal"
                      pattern="\d*"
                      name="reg_phone_no"
                      autoComplete="off"
                      value={this.state.redeemAmount}
                      placeholder="Enter Redeem Amount"
                      onChange={this.handleFldChange.bind(this)}
                    />
                  </div>
                  {/* this.state.regphone_error != "" && (
                    <span className="error-info">
                      {this.state.regphone_error}
                    </span>
                  ) */}
                </div>
                <div className="bottom-btn bb-txt textcenter">
                  <a
                    href="/"
                    className="button full-btn reg-otp-link"
                    onClick={this.scanRedeemQR.bind(this)}
                  >
                    Continue to scan QR
                  </a>
                </div>
              </div>
            </div>
          </BottomSheet>
        </div>
      );
    } else {
      return (
        <div className="main-div">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div className="rel">
            <div className="container">
              <Instagram
                backgroundColor={"#c7c7c7"}
                foregroundColor={"#c7c7c7"}
              />
            </div>
          </div>
          <Footer {...this.props} />
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  var missionslistArr = [];
  if (Object.keys(state.missionslist).length > 0) {
    if (state.missionslist[0].status === "ok") {
      missionslistArr = state.missionslist[0].result;
    }
  }
  var rewardsettingsArr = [];
  if (Object.keys(state.rewardsettingslist).length > 0) {
    if (state.rewardsettingslist[0].status === "ok") {
      rewardsettingsArr = state.rewardsettingslist[0].result;
    }
  }

  return {
    customerdetails: customerdetailsArr,
    missionslis: missionslistArr,
    rewardsettingslist: rewardsettingsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getMissionsList: (params) => {
      dispatch({ type: GET_MISSIONS_LIST, params });
    },
    getRewardSettingList: (params) => {
      dispatch({ type: GET_REWARDSETTING_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Myaccount));

/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { apiUrl, unquieID, headerconfig } from "../Settings/Config";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import homeIcon from "../../common/images/home-icon.svg";
import subscriptionicon from "../../common/images/subscription-icon.svg";
import referral from "../../common/images/referral-nav.svg";
import voc from "../../common/images/voucher-ticket.svg";



class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { totalSubscription: 0, totalVouchers: 0 };
  }

  componentDidMount() {
    console.log(this.props.match.path, "this.props.match.path");
    if (this.props.match.path !== "/home") {
      this.loadStatistics();
    }

    $("body").addClass("hide-overlay");
  }
  loadStatistics() {
    if (
      unquieID !== "" &&
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      axios
        .get(
          apiUrl +
            "customer/getStatistics?customer_id=" +
            cookie.load("UserId") +
            "&UniqueID=" +
            unquieID,
          headerconfig
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({
              totalSubscription: res.data.totalSubscription,
              totalVouchers: res.data.totalVoucher,
            });
          } else {
            this.setState({ subscriptionLoad: false });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  render() {
    return (
      <footer className="footer-main">
        <IonFooter collapse="fade">
          <div className="nav-full">
            <Link to={"/"} title="Home" className="active">
              <img src={homeIcon} /> <span>Home</span>
            </Link>
            <Link to={"/subscription"} title="Subscription">
              <figure>
                <img src={subscriptionicon} alt="Subscription" />{" "}
                {this.state.totalSubscription > 0 && (
                  <em>{this.state.totalSubscription}</em>
                )}
              </figure>
              <span>Subscription</span>
            </Link>
            <Link to={"/buy-vouchers"} title="Vouchers">
              <figure>
                <img src={voc} alt="Vouchers" />
                {this.state.totalVouchers > 0 && (
                  <em>{this.state.totalVouchers}</em>
                )}
              </figure>
              <span>Vouchers</span>
            </Link>
            <Link to={"/share"} title="Refer">
              <img src={referral} /> <span>Refer</span>
            </Link>
          </div>
        </IonFooter>
      </footer>
    );
  }
}

export default Footer;

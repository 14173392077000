import React, { Component } from "react";
import cookie from "react-cookies";
import { baseUrl } from "../Settings/Config";

class Logout extends Component {
  componentWillMount() {
    cookie.remove("UserId", { path: "/" });
    cookie.remove("UserFname", { path: "/" });
    cookie.remove("UserLname", { path: "/" });
    cookie.remove("UserMobile", { path: "/" });
    cookie.remove("UserEmail", { path: "/" });
    cookie.remove("DirectQRCode", { path: "/" });

    cookie.remove("IsVerifiedUser", { path: "/" });
    cookie.remove("triggerOTP", { path: "/" });
    cookie.remove("triggerFrom", { path: "/" });

    localStorage.removeItem("company_id");
    localStorage.removeItem("company_app_id");
    localStorage.removeItem("company_logo");
    localStorage.removeItem("selectedcompany_data");
    localStorage.removeItem("token");
    

    setTimeout(function () {
      window.location.href = baseUrl;
    }, 0);
  }

  render() {
    return <h1 className="loading-text">Logging out...</h1>;
  }
}

export default Logout;

import React, { Component } from "react";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import companyLogo from "../../common/images/companyLogo.png";
import arrow from "../../common/images/arrow.svg";
import googlePay from "../../common/images/payment/google-pay.svg";
import amexPay from "../../common/images/payment/american-express.svg";
import applePay from "../../common/images/payment/apple_logo.svg";
import atome from "../../common/images/payment/atome.svg";
import paynow from "../../common/images/payment/PayNowLogo.svg";
import grabpay from "../../common/images/payment/GrabPay.svg";
import mastercard from "../../common/images/payment/mastercard.svg";
import visa from "../../common/images/payment/visa.svg";
import mswipe from "../../common/images/payment/mswipe.svg";
class DirectPay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: "applepay",
    };
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
  }

  render() {
    return (
      <div className="directpay-new">
        <header className="header">
          <div className="header-background">
            <div className="header-action header-center-txt">
              <div className="container">
                <div className="ha-lhs"></div>
                <div className="ha-rhs">
                  <ul>
                    <li className="profile-user">
                      <a href="/">
                        <img
                          src="/static/media/user.529b688b202e33d5cb9ed9fdbd5e666d.svg"
                          alt="user"
                        />
                      </a>
                    </li>
                    <li className="navsbar">
                      <a href="/">
                        <img
                          src="/static/media/navigation.55fc7d1db67e06740b886e7c6bb60183.svg"
                          alt="nav"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="header-content">
            <div className="restaurant-info">
              <img
                src={companyLogo}
                alt="Aldenaire Kitchen"
                className="restaurant-logo"
              />
              <div className="restaurant-info-details">
                <h1 className="restaurant-name">Aldenaire Kitchen</h1>
                <p className="restaurant-location">Bugis Street</p>
              </div>
            </div>
            <div className="table-info">
              <span className="table-number">Table 5</span>
              <a href="#" className="view-menu">
                View Menu
              </a>
            </div>
          </div>
        </header>

        <div className="container">
          <div className="payment-container">
            <div className="order-details">
              <div className="customer-amount-section">
                <span>$</span>
                <input
                  type="text"
                  placeholder="Enter Custom Amount"
                  className="custom-tip-input"
                />
              </div>
              <div className="bill-section">
                <div className="item-list">
                  <div className="item">
                    <p>Cafe Late</p>
                    <div className="item-qty-price">
                      <span className="qty">2x</span>
                      <span className="price">$15.00</span>
                    </div>
                  </div>
                  <div className="item">
                    <p>Chicken Burger</p>
                    <div className="item-qty-price">
                      <span className="qty">1x</span>
                      <span className="price">$14.00</span>
                    </div>
                  </div>
                  <div className="item">
                    <p>Coke Can</p>
                    <div className="item-qty-price">
                      <span className="qty">2x</span>
                      <span className="price">$15.00</span>
                    </div>
                  </div>
                  <div className="item">
                    <p>Fusilli</p>
                    <div className="item-qty-price">
                      <span className="qty">1x</span>
                      <span className="price">$18.00</span>
                    </div>
                  </div>
                </div>

                <div className="subtotal-section">
                  <div className="subtotal">
                    <span>Subtotal</span>
                    <span>$62.00</span>
                  </div>
                  <div className="service-charge">
                    <span>Service Charge</span>
                    <span>$2.50</span>
                  </div>
                  <div className="gst">
                    <span>GST 9%</span>
                    <span>$5.40</span>
                  </div>
                  <div className="shared">
                    <span>Shared with 1</span>
                    <span>($10.00)</span>
                  </div>
                  <div>
                    <div className="payable">
                      <span>Payable Amount</span>
                      <span>$59.90</span>
                    </div>

                    <small>Inclusive of all taxes and charges</small>
                  </div>
                  <div>
                    <div className="your-share">
                      <span>Your Share</span>
                      <span>$5.00</span>
                    </div>
                    <small>You are paying for your share</small>
                  </div>
                </div>

                <div className="actions">
                  <button className="split-bill">Edit Split</button>
                  <button className="pay-total">Pay Total</button>
                </div>

                <div className="tip-section">
                  <p>Do you want to include a tip</p>
                  <div className="tip-options">
                    <button className="tip-option">Not Now</button>
                    <button className="tip-option selected">3%</button>
                    <button className="tip-option">5%</button>
                    <button className="tip-option">7%</button>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Custom Amount"
                    className="custom-tip-input"
                  />
                  <div className="tip-amount">
                    <span>Tip amount :</span>
                    <span>$2.50</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vouchersList">
            <h2>Use Cash Vouchers to Pay</h2>
            <div className="vouchers ">
              <div className="voucher selected">
                <h2>
                  <span>10%</span>
                  <br />
                  Discount
                </h2>
                <p>Pay</p>
                <p className="pay-amount">$50</p>
                <p className="plus">+</p>
                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">$5</p>
                </div>
              </div>
              <div className="voucher">
                <h2>
                  <span>10%</span>
                  <br />
                  Discount
                </h2>
                <p>Pay</p>
                <p className="pay-amount">$50</p>
                <p className="plus">+</p>
                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">$5</p>
                </div>
              </div>
              <div className="voucher">
                <h2>
                  <span>5%</span>
                  <br />
                  Discount
                </h2>
                <p>Pay</p>
                <p className="pay-amount">$50</p>
                <p className="plus">+</p>
                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">$5</p>
                </div>
              </div>
              {/* <div className="voucher">
      <h2>15% Discount</h2>
      <p>Pay <strong>$100</strong></p>
      <p>+ Bonus <span className="bonus">$15</span></p>
    </div>
    <div className="voucher">
      <h2>20% Discount</h2>
      <p>Pay <strong>$200</strong></p>
      <p>+ Bonus <span className="bonus">$40</span></p>
    </div> */}
            </div>
            <div className="arrow">
              <img src={arrow} alt="Arrow" />
              <p>See how this works</p>
            </div>
          </div>

          <div className="payment-sec">
            <div className="details">
              <p>
                You Pay <span>$50.00</span>
              </p>
              <p>
                Receive <span>$55.00</span>
              </p>
              <p>
                Bill <span>$5.00</span>
              </p>
              <p>
                Tip <span>$2.50</span>
              </p>
              <p>
                Balance For Next Visit <span>$48.50</span>
              </p>
              <p className="total">
                Total <span>$50.00</span>
              </p>
            </div>

            <div className="payment-method">
              <h3>Payment Method</h3>

              {/*  <div className="payment-option">
      <input type="radio" id="card" name="payment" checked />
      <label for="card">
        Credit or Debit Card
        <img src="https://img.icons8.com/color/48/000000/visa.png" alt="Visa" />
        <img src="https://img.icons8.com/color/48/000000/mastercard.png" alt="MasterCard" />
      </label>
    </div> */}
              <div className="payment-list selected">
                <span>
                  <input type="radio" id="card" name="payment" checked />
                  {/* <div className="apple-dot"></div> */} Credit or Debit Card
                </span>
                <img
                  src="https://img.icons8.com/color/48/000000/mastercard.png"
                  alt="MasterCard"
                />
              </div>

              <div className="payment-list">
                <span>
                  <input type="radio" id="card" name="payment" />
                  {/* <div className="apple-dot"></div> */} Apple Pay
                </span>
                <img
                  src="https://img.icons8.com/ios-filled/50/000000/apple-pay.png"
                  alt="Apple Pay"
                />
              </div>
            </div>
          </div>
        </div>
        <IonFooter collapse="fade">
          <div className="sticky-single-btn">
            <a
              href={() => false}
              className="button green-btn place-order-link"
              id="placeOredr"
              /*  onClick={this.payBill.bind(this)} */
            >
              Pay with{" "}
              <span>
                {" "}
                {(() => {
                  if (this.state.paymentMethod === "mv3ds") {
                    return (
                      <>
                        <img
                          src={mastercard}
                          alt="Master"
                          className="master-card"
                        />
                        <img src={visa} alt="Visa" className="visa-card" />
                      </>
                    );
                  } else if (this.state.paymentMethod === "applepay") {
                    return (
                      <img
                        src={applePay}
                        alt="ApplePay"
                        className="apple-icon"
                      />
                    );
                  } else if (this.state.paymentMethod === "amex3ds") {
                    return (
                      <img
                        src={amexPay}
                        alt="ApplePay"
                        className="amexpay-icon"
                      />
                    );
                  } else if (this.state.paymentMethod === "googlepay") {
                    return (
                      <img
                        src={googlePay}
                        alt="ApplePay"
                        className="googlepay-icon"
                      />
                    );
                  } else if (this.state.paymentMethod === "atome") {
                    return (
                      <img src={atome} alt="ApplePay" className="atome-icon" />
                    );
                  } else if (this.state.paymentMethod === "paynow") {
                    return (
                      <img
                        src={paynow}
                        alt="ApplePay"
                        className="paynow-icon"
                      />
                    );
                  } else if (this.state.paymentMethod === "grabpay") {
                    return (
                      <img
                        src={grabpay}
                        alt="ApplePay"
                        className="grabpay-icon"
                      />
                    );
                  } else if (this.state.paymentMethod === "MSwipe") {
                    return (
                      <img
                        src={mswipe}
                        alt="ApplePay"
                        className="mswipe-icon"
                      />
                    );
                  }
                })()}
                <label>Pay</label>
              </span>
            </a>
            <div>
              <span className="error">{this.state.error_msg}</span>
            </div>
          </div>
        </IonFooter>
      </div>
    );
  }
}

export default DirectPay;

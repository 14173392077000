import React, { Component } from "react";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import companyLogo from "../../common/images/companyLogo.png";
import arrow from "../../common/images/arrow.svg";
import menuicon from "../../common/images/menu-icon.svg";
import paybillicon from "../../common/images/paybill-icon.svg";
import offerimage from "../../common/images/offerimage.png";
import coin from "../../common/images/coin.svg";
import voucherticket from "../../common/images/voucher-ticket.svg";




class DirectPay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod:'applepay'
    };
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
  }

  render() {
    return (
      <div className="directpay-new home-page">
        <header className="header">
          <div className="header-background">
            <div className="header-action header-center-txt">
            <div className="container">
              <div className="ha-lhs"></div>
              <div className="ha-rhs">
                <ul>
                  <li className="profile-user">
                    <a href="/">
                      <img
                        src="/static/media/user.529b688b202e33d5cb9ed9fdbd5e666d.svg"
                        alt="user"
                      />
                    </a>
                  </li>
                  <li className="navsbar">
                    <a href="/">
                      <img
                        src="/static/media/navigation.55fc7d1db67e06740b886e7c6bb60183.svg"
                        alt="nav"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            </div>
          </div>
          <div className="header-content">
            <div className="restaurant-info">
              <img
                src={companyLogo}
                alt="Aldenaire Kitchen"
                className="restaurant-logo"
              />
              <div className="restaurant-info-details">
              <p className="restaurant-location">Welcome</p>
                <h1 className="restaurant-name">Gentleman Barber Shop</h1>
                
              </div>
            </div>
          </div>
        </header>

        <div className="container">
        <div className="home-menu">
       
        <div className="login-section">
            <a href="#" className="login-link">If you already have an account, <span>Click here to Login</span></a>
        </div>

       
        <div className="button-container">
            <a href="#" className="btn view-menu">
              <img src={menuicon} alt="" />
              <span>View Menu</span>
              </a>
            <a href="#" className="btn pay-bill">
            <img src={paybillicon} alt="" />
              <span>Pay Bill</span>
              
              </a>
        </div>

      
        <div className="promotion-section">
            <img src={offerimage} alt="Breakfast offer" className="promo-image" />
            <div className="promo-text">
                <p>Sign up to collect your welcome voucher</p>
            </div>
        </div>

        <div className="cash-voucher-section">
          {/* <div className="voucher-line-par">
          <div className="voucher-line-inner"></div>
          </div> */}
        <div className="voucher-card">
            <div className="voucher-info">
                <div className="voucher-title">Your Cash Vouchers</div>
                <div className="voucher-amount"><img src={voucherticket} alt="" />$2,345.00</div>
            </div>
            <a href="#" className="redeem-btn">Redeem</a>
        </div>

        <div className="points-card">
            <div className="points-info">
                <div className="points-title">Earned Points</div>
                <div className="points-amount"><img src={coin} alt="" /> 48.50</div>
            </div>
            <a href="#" className="redeem-btn">Redeem</a>
        </div>
    </div>

    </div>

          <div className="vouchersList">
            <h1>Enjoy Big Bonus</h1>
            <h2>Use Cash Vouchers to Pay</h2>
            <div className="vouchers ">
              <div className="voucher">
                <h2>
                  <span>
                  10%
                  </span>
                  <br />
                  Discount
                </h2>
                <p>Pay</p>
                <p className="pay-amount">$50</p>
                <p className="plus">+</p>
                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">$5</p>
                </div>
              </div>
              <div className="voucher">
                <h2>
                <span>
                  10%
                  </span>
                  <br />
                  Discount
                </h2>
                <p>Pay</p>
                <p className="pay-amount">$50</p>
                <p className="plus">+</p>
                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">$5</p>
                </div>
              </div>
              <div className="voucher">
                <h2>
                <span>
                  5%
                  </span>
                  <br />
                  Discount
                </h2>
                <p>Pay</p>
                <p className="pay-amount">$50</p>
                <p className="plus">+</p>
                <div className="bonus">
                  <p>Bonus</p>
                  <p className="bonus-amount">$5</p>
                </div>
              </div>
            </div>
            <div className="arrow">
              <img src={arrow} alt="Arrow" />
              <p>See how this works</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DirectPay;
